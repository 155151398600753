<template>
	<div
		class="tel-input"
		:class="{
			'tel-input--error': isEnterEnd
		}"
	>
		<vue-tel-input
			v-model="phoneModel"
			mode="international"
			:inputOptions="inputOptions"
			:dropdownOptions="dropdownOptions"
			validCharactersOnly
			:autoFormat="true"
			autoDefaultCountry
			:invalidMsg="$t('validations.phoneInvalid')"
			@country-changed="cleanPhoneNumber"
			ref="telInput"
			@blur="isEnterEnd = true"
		/>
<!--    <div class="input-component__error" v-if="!isValid">-->
<!--		  {{ $t("validations.phoneInvalid") }}-->
<!--    </div>-->
	</div>
</template>

<script setup lang="js">
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css'
import { computed, ref } from "vue";

const props = defineProps(["modelValue", 'isValid']);
const emits = defineEmits(["update:modelValue"]);

const phoneModel = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits("update:modelValue", value.replace(/\s/g, ''));
  },
})

const isEnterEnd = ref(false);
const inputOptions = {
  showDialCode: true,
  required: true,
  maxlength: 15,
  autocomplete: 'on',
  name: 'telephone',
  type: 'tel',
  'aria-describedby': 'Search by country name or country code',
};
const dropdownOptions = {
  disabled: false,
  showDialCodeInList: true,
  showDialCodeInSelection: false,
  showFlags: true,
  // showSearchBox: true,
  tabIndex: 0
};

function cleanPhoneNumber() {
  emits("update:modelValue", "");
}

// function checkValidity(event) {
//   isValidModel = event.valid;
// }

// export default {
//   name: 'TelInput',
//   components: {
//     VueTelInput,
//   },
//   emits: [ "update:modelValue"],
//   props: [ "modelValue", 'isValid'],
//   data(){
//     return {
//       isFinished: false,
//       isEnterEnd: false,
//       isMounted: false,
//       inputOptions: {
//         showDialCode: true,
//         required: true,
//       },
//     }
//   },
//   computed: {
//     /*isValidModel: {
//       get() {
//         return this.name;
//       },
//       set(value) {
//         this.$emit("update:valid", value);
//       },
//     },*/
//     phoneModel: {
//       get() {
//         return this.modelValue;
//       },
//       set(value) {
//         this.$emit("update:modelValue", value);
//       },
//     },
//   },
//   methods: {
//     checkValidity(event) {
//       this.isValidModel = event.valid;
//     },
//     cleanPhoneNumber() {
//       this.$refs.telInput.phone = `+${this.$refs.telInput.activeCountry?.dialCode}`;
//     },
//   },
// }
</script>

<style lang="scss">
.tel-input {
	width: 98%;
	max-width: 250px;
	ul {
		z-index: 10;
	}
	& > .vue-tel-input {
		height: 50px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 16px;
		color: #3b3f47;
		border: 1px solid rgba(0, 0, 0, 0.1) !important;
		border-radius: 4px;
		transition: all 0.5s linear;

		&:hover,
		&:focus,
		&:active {
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-color: #c99d56 !important;
			transition: all 0.5s linear;
		}
	}
	&__msg {
		color: var(--error-clr);
		font-size: 12px;
		position: absolute;
		top: calc(100% + 3px);
	}
	&--error {
		position: relative;
		.vue-tel-input {
			border-color: var(--error-clr);
			&:focus-within {
				border-color: var(--error-clr);
			}
			&:hover {
				border-color: var(--error-clr);
			}
		}
	}
	@media screen and (max-width: 600px) {
		.vti__dropdown-list {
			max-width: 280px;
		}
	}
	@media screen and (max-width: 360px) {
		.vti__dropdown-list {
			left: -30px;
			max-width: 280px;
		}
	}
}
</style>
